<template>
  <Layout class="main-cols">
    <div class="container is-fluid md:ml-0" v-if="getDetalle != null">
      <div class="flex md:items-center flex-col md:flex-row">
        <b-icon
          icon="arrow-left"
          class="has-text-primary has-background-primary-02 p-5 rounded-full ml-1 cursor-pointer"
          @click.native="$router.back()"
        />
        <p class="text-2xl font-bold md:ml-4 -ml-8">
          Supervisión con folio #{{ $route.params.idSupervision }}
        </p>
      </div>
      <tabs
        :tabs="tabs"
        v-model="tab"
        size="is-medium"
        type="default"
        :expanded="false"
      />
      <informacion
        @reagendar="() => (reagendar = true)"
        :supervision="getDetalle"
        v-if="tab == 0"
      />
      <requisitos :supervision="getDetalle" v-if="tab == 1" />
    </div>
    <portal to="modalContainer">
      <b-modal
        v-if="reagendar"
        :active.sync="reagendar"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <modal-reagendar-supervision :supervision="getDetalle" />
      </b-modal>
    </portal>
  </Layout>
</template>

<script>
import { mapGetters } from "vuex";
import ModalReagendarSupervision from "@/components/modals/ModalReagendarSupervision";
import Informacion from "@/components/supervision/Informacion";
import Requisitos from "@/components/supervision/Requisitos";
import Tabs from "@/components/generals/Tabs";

export default {
  name: "DetalleSupervision",
  components: {
    ModalReagendarSupervision,
    Tabs,
    Informacion,
    Requisitos
  },
  data() {
    return {
      tab: 0,
      reagendar: false,
      tabs: [
        {
          label: "Información"
        },
        {
          label: "Requisitos"
        }
      ]
    };
  },
  watch: {
    $route: {
      handler: function() {
        if (
          (this.getDetalle &&
            this.getDetalle.id !== this.$route.params.idSupervision) ||
          !this.getDetalle
        ) {
          this.$store.dispatch("supervision/resetState");
          this.$store.dispatch(
            "supervision/getDetalle",
            this.$route.params.idSupervision
          );
        }
        if (
          (this.getDisposicionDetalle &&
            this.getDisposicionDetalle.id !==
              this.$route.params.idDisposicion) ||
          !this.getDisposicionDetalle
        ) {
          this.$store.dispatch("disposicion/resetState");
          this.$store.dispatch(
            "disposicion/getDisposicionDetalle",
            this.$route.params.idDisposicion
          );
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters("supervision", ["getDetalle"])
  },
  mounted() {
    this.$store.dispatch(
      "supervision/getDetalle",
      this.$route.params.idSupervision
    );

    this.$store.dispatch(
      "disposicion/getDisposicionDetalle",
      this.$route.params.idDisposicion
    );
    if (this.$route.query.tab) {
      this.tab = parseInt(this.$route.query.tab);
    }
  }
};
</script>
