<template>
  <div class="columns">
    <div class="column is-4 text-left">
      <p class="text-6xl font-bold">
        {{ supervision.fecha_sugerida | moment("DD") }}
      </p>
      <p class="text-3xl -mt-4 capitalize font-light">
        {{ supervision.fecha_sugerida | moment("MMMM") }}
      </p>
      <p class="text-2xl mt-2">
        {{
          supervision.fecha_supervision == null
            ? "Sin realizar"
            : "Realizada el " + supervision.fecha_supervision
        }}
      </p>
      <div class="mt-4">
        <p class="font-light has-text-grey-light">Fecha sugerida</p>
        <p class="has-text-success text-lg">
          {{ supervision.fecha_sugerida | moment("DD-MM-YYYY") }}
        </p>
        <p class="font-light has-text-grey-light mt-3">
          Fecha reagendada
        </p>
        <p class="text-lg flex items-center">
          {{ fecha_reagendada }}
          <b-button
            class="ml-2"
            type="is-light"
            icon-left="calendar-alt"
            @click="$emit('reagendar')"
          ></b-button>
        </p>
        <p class="font-light has-text-grey-light mt-3">Supervisor</p>
        <p class="text-lg">
          {{ supervision.persona.nombre_completo.split("|")[0] }}
        </p>
        <p class="font-light has-text-grey-light mt-3">Acreditado</p>
        <p class="text-lg">
          {{ supervision.credito_core.cliente.nombre_completo.split("|")[0] }}
        </p>
      </div>
    </div>
    <div class="column hidden md:block">
      <b-image src="/images/detalle_supervision.svg" alt class="w-full" />
    </div>
  </div>
</template>

<script>
export default {
  name: "supervisionInformacion",
  props: {
    supervision: Object
  },
  computed: {
    fecha_reagendada() {
      return this.supervision.fecha_reagendada
        ? this.$moment(this.supervision.fecha_reagendada)
            .format("DD-MM-YYYY")
            .toDate()
        : "Sin reagendar";
    }
  }
};
</script>
