<template>
  <div>
    <radio-group
      class="mt-4 hidden md:block"
      :buttons="radio_buttons"
      v-model="view_type"
    />
    <div v-if="view_type == 0">
      <div class="columns mt-6">
        <div
          class="column is-8"
          v-if="supervision.credito_requisitos.length > 0"
        >
          <div class="columns is-multiline">
            <div
              v-for="requisito in supervision.credito_requisitos"
              :key="requisito.id"
              class="column is-6"
            >
              <requisito-card
                @select="selectRequisito"
                :requisito="requisito"
                :selected_id="requisito_selected.id"
              />
            </div>
          </div>
        </div>
        <div
          class="column is-4"
          v-if="supervision.credito_requisitos.length > 0"
        >
          <sidebar
            :requisito="requisito_selected"
            @cambiar-status="changeStatus"
            @desligar-doc="desligarDoc"
            @toggle-cf="toggleCapvitalFiles"
            @enviar-comentario="enviarComentario"
            :all-status="getRequisitoStatus"
          />
        </div>
        <div v-else class="column is-12">
          <empty-state
            type="svg"
            :vertical="true"
            imagen="/images/requisito_placeholder.svg"
            imgclass="w-2/5 mb-4"
            titulo="No hay requisitos"
          />
        </div>
      </div>
    </div>
    <div v-if="view_type == 1" class="mt-4">
      <kanban
        :lists="lists"
        :statuses="getRequisitoStatus"
        @show="showRequisitoModal"
        @change-status="changeStatus"
      />
    </div>
    <portal to="modalContainer">
      <b-modal
        v-if="modalActive"
        :active.sync="modalActive"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <modal-requisitos
          :requisito="requisito_selected"
          @enviar-comentario="enviarComentario"
          :all-status="getRequisitoStatus"
          @cambiar-status="changeStatus"
          @toggle-cf="toggleCapvitalFiles"
          @desligar-doc="desligarDoc"
        />
      </b-modal>
    </portal>
  </div>
</template>

<script>
import EmptyState from "@/components/generals/EmptyState";
import RadioGroup from "@/components/generals/RadioGroup";
import Sidebar from "@/components/generals/SidebarRequisitos";
import RequisitoCard from "@/components/cards/RequisitoCard";
import ModalRequisitos from "@/components/modals/ModalRequisitos";
import Kanban from "@/components/generals/Kanban";
import { mapGetters } from "vuex";

export default {
  name: "requisitosSupervision",
  components: {
    EmptyState,
    Sidebar,
    RequisitoCard,
    ModalRequisitos,
    RadioGroup,
    Kanban
  },
  data() {
    return {
      modalActive: false,
      view_type: false,
      lists: {
        list_null: []
      },
      requisito_selected: {
        id: null
      },
      radio_buttons: [
        {
          label: "Normal",
          icon: "grip-vertical",
          value: false
        },
        {
          label: "Kanban",
          icon: "columns",
          value: true
        }
      ]
    };
  },
  computed: {
    ...mapGetters("supervision", ["getRequisitoStatus", "getDetalle"]),
    ...mapGetters("files", ["getStatus"])
  },
  props: {
    supervision: Object
  },
  watch: {
    /*
      cambia la vista dependiendo si es kanban(1) o normal(0),
      @type (Integer) 0 => Normal, 1 => Kanban
    */
    view_type: function(type) {
      if (type == 1) {
        this.lists.list_null = this.requisitosByStatus(null);
        this.getRequisitoStatus.forEach(status => {
          this.lists["list_" + status.id] = this.requisitosByStatus(status.id);
        });
      }
      this.requisito_selected = {
        id: null
      };
    },
    /*
      Comprueba si el prop de supervisión ha cambiado y asigna el requisito seleccionado
      @data(Object Supervision)
    */
    supervision: function(data) {
      if (this.requisito_selected.id != null) {
        this.requisito_selected = data.credito_requisitos.find(
          x => x.id == this.requisito_selected.id
        );
      }
    },
    getStatus() {
      this.updateRequisitos(this.getRequisitos);
    }
  },
  methods: {
    //Muestra el detalle del requisito
    showRequisitoModal(requisito) {
      this.modalActive = true;
      this.requisito_selected = requisito;
    },
    //Muestra el detalle del requisito
    selectRequisito(requisito) {
      if (
        Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0
        ) >= 768
      ) {
        this.requisito_selected = requisito;
        return;
      }
      this.modalActive = true;
      this.requisito_selected = requisito;
    },
    /*
      Filtra los requisitos por status en un arreglo
      - @status_id(Integer)
      - return Array Requisitos
    */
    requisitosByStatus(status_id) {
      return this.supervision.credito_requisitos.filter(
        x => x.status_id == status_id
      );
    },
    changeStatus(form) {
      this.$store
        .dispatch("supervision/changeRequisitoStatus", form)
        .then(data => {
          this.updateRequisitos(data);
        });
    },
    desligarDoc(data) {
      this.$store.dispatch("supervision/desligarArchivos", data).then(data => {
        this.updateRequisitos(data);
      });
    },
    toggleCapvitalFiles(data) {
      data.type = "requisitos_supervision";
      data.persona = this.getDetalle.credito_core.cliente;
      this.$store.dispatch("files/toggleCapvitalFiles", data);
    },
    enviarComentario(data) {
      this.$store
        .dispatch("supervision/agregarComentarioRequisito", data)
        .then(data => {
          this.updateRequisitos(data);
        });
    },
    updateRequisitos(data) {
      if (this.view_type == 0) {
        this.requisito_selected = data.find(
          x => x.id == this.requisito_selected.id
        );
      } else {
        this.lists.list_null = this.requisitosByStatus(null);
        this.getRequisitoStatus.forEach(status => {
          this.lists["list_" + status.id] = this.requisitosByStatus(status.id);
        });
        if (this.requisito_selected) {
          this.requisito_selected = data.find(
            x => x.id == this.requisito_selected.id
          );
        }
      }
    }
  },
  mounted() {
    if (this.getRequisitoStatus == null) {
      this.$store.dispatch("supervision/getRequisitoStatus");
    }
  }
};
</script>
